import { customRef } from 'vue';
import BaseCollection from '~~/app/base/collections/BaseCollection';
import BaseModel from '~~/app/base/models/BaseModel';

function reactiveModel<Model extends BaseModel>(value: Model | null) {
    return customRef((track, trigger) => {
        return {
            get() {
                track();
                return value;
            },
            set(newValue) {
                if (value) {
                    value.destroy();
                }
                value = newValue;
                value?.setup();
                // console.log('On Vue set of model', value);
                value?.setOnUpdateVueCallback(() => {
                    // console.log('On Vue Update of model', value);
                    trigger();
                });
                trigger();
            },
        };
    });
}

function reactiveCollection<Model extends BaseModel, Collection extends BaseCollection<Model>>(value: Collection | null) {
    return customRef((track, trigger) => {
        return {
            get() {
                track();
                return value;
            },
            set(newValue) {
                if (value) {
                    value.destroy();
                }
                value = newValue;
                value?.setOnUpdateVueCallback(() => {
                    // console.log('On Vue Update of collection', value);
                    trigger();
                });
                value?.setup();
                trigger();
            },
        };
    });
}

function destroyModels(instance) {
    // console.log(instance);
}

export { reactiveModel, reactiveCollection, destroyModels };
